import Email from "@material-ui/icons/Email";
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import Close from "@material-ui/icons/Close";
import BusinessIcon from '@material-ui/icons/Business';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import DomainIcon from '@material-ui/icons/Domain';
import SearchIcon from '@material-ui/icons/Search';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import Check from '@material-ui/icons/Check';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LensIcon from '@material-ui/icons/Lens';
import Icon from "@material-ui/core/Icon";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import FaceIcon from '@material-ui/icons/Face';
import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SaveIcon from '@material-ui/icons/Save';
import GroupIcon from '@material-ui/icons/Group';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import UpdateIcon from '@material-ui/icons/Update';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DehazeIcon from '@material-ui/icons/Dehaze';
import PaletteIcon from '@material-ui/icons/Palette';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import SecurityIcon from '@material-ui/icons/Security';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import StarsIcon from '@material-ui/icons/Stars';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';
export {InsertDriveFileIcon,NoteAddIcon,DeleteForeverIcon, BusinessIcon, Close as CloseIcon, DomainIcon,StarBorderIcon,AddIcon,EditIcon,InfoIcon,SearchIcon,AccountBoxTwoToneIcon,PeopleAltIcon,StarsIcon,LocalOfferIcon,SecurityIcon,DevicesOtherIcon,PaletteIcon,DehazeIcon,AttachMoneyIcon,UpdateIcon,CardTravelIcon,GroupIcon,SaveIcon,PersonOutlineIcon,PersonIcon,ListIcon,FaceIcon,AcUnitIcon,SettingsIcon,AccountCircleIcon,AccountTreeIcon,PhoneIphoneIcon,Icon,LensIcon,ExpandLess,ExpandMore,AccountBoxIcon,CardMembershipIcon,BubbleChartIcon,DeveloperModeIcon,AssignmentIcon,LayersIcon,DashboardIcon,ShoppingCartIcon,PeopleIcon,BarChartIcon,MailOutlineIcon, LockOpenIcon,Check as CheckIcon,Email as EmailIcon,LockOutlinedIcon,MenuIcon,ChevronLeftIcon,NotificationsIcon,MeetingRoomIcon};