import {
    LOGIN,
    RENOVAR_TOKEN,
    RESTABLECER_TOKEN,
    LOGOUT, UPDATE_THEME_USER,
    UPDATE_REGULACIONES,
    USER_ALERT,
    UPDATE_USER_DATA,
    UPDATE_NOMBRE_ALIAS_EMPRESA
} from "../Types";

//***DEPENDENCIAS***/
import Swal from "sweetalert2";

export const login = usuario => {
    return {
        type: LOGIN,
        payload: usuario
    }
}

export const logout = () => {
    localStorage.removeItem('token')
    return {
        type: LOGOUT,
        payload: null
    }
}

export const renovarToken = (datos) => {
    return {
        type: RENOVAR_TOKEN,
        payload: datos
    }
}

export const restablecerToken = () => {
    localStorage.removeItem('token');
    return {
        type: RESTABLECER_TOKEN,
        payload: null
    }
}

export const updateThemeUser = (datos) => {
    return {
        type: UPDATE_THEME_USER,
        payload: datos
    }
}

export const updateRegulaciones = (datos) => {
    return {
        type: UPDATE_REGULACIONES,
        payload: datos
    }
}

export const updateUserData = (datos) =>{
    return {
        type: UPDATE_USER_DATA,
        payload: datos
    }
}

export const updateNombreAliasEmpresaData = (datos) =>{
    return {
        type: UPDATE_NOMBRE_ALIAS_EMPRESA,
        payload: datos
    }
}

export const userAlert = (datos) =>{
    localStorage.removeItem('token');
    return {
        type: USER_ALERT,
        payload: datos
    }
} 

export const verificarToken = (resp) => {
    return async (dispatch) => {
        if (resp.error === null || resp.error === true) {
            switch (resp.error) {
                case null:
                    dispatch(restablecerToken());
                    break;
                case true:
                    dispatch(userAlert({ tipo: "advertencia", titulo: "Mantenimiento", contenido: "La pagina estara disponible pronto" }));
                    break;
                default:
                    break;
            }
        } else {
            try {
                if (resp?.data?.renewTokenUser?.response?.success) {
                    dispatch(renovarToken({
                        apellidoMaterno:resp.data.renewTokenUser.apellidoMaterno,
                        apellidoPaterno:resp.data.renewTokenUser.apellidoPaterno,
                        telefonoCasa:resp.data.renewTokenUser.telefonoCasa,
                        telefonoCelular:resp.data.renewTokenUser.telefonoCelular,
                        fechaNacimiento:resp.data.renewTokenUser.fechaNacimiento,
                        idUsuario: resp.data.renewTokenUser.idUsuario,
                        nombre: resp.data.renewTokenUser.nombre,
                        email: resp.data.renewTokenUser.email,
                        tipoUsuario: resp.data.renewTokenUser.tipoUsuario,
                        theme: resp.data.renewTokenUser.response.theme,
                        iva:resp.data.renewTokenUser.configuracion.iva,
                        serie:resp.data.renewTokenUser.configuracion.serie,
                        idEntidad:resp.data.renewTokenUser.configuracion.idEntidad,
                        nombreEmpresa:resp.data.renewTokenUser.configuracion.nombreEmpresa,
                        aliasEmpresa:resp.data.renewTokenUser.configuracion.aliasEmpresa,
                        claveAcceso:resp.data.renewTokenUser.configuracion.claveAcceso,
                    }));
                } else {
                    dispatch(restablecerToken());
                }
            } catch (e) {
                Swal.fire(
                    "Operacion Fallida",
                    "Error inesperado, vuelve intentalor más tarde",
                    "warning"
                );
            }
        }

    }
}