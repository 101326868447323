import React,{ useEffect } from 'react';
import { createContext } from 'react';
//***HOOKS***
import { useSocket } from '../Hooks/Socket'
//***REDUX***
import { useSelector,useDispatch } from "react-redux";
import { logout } from "../../Redux/Actions/authAction";

const { REACT_APP_API_URL } = process.env;
export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {

    const { socket, online, conectarSocket, desconectarSocket } = useSocket(REACT_APP_API_URL||'http://localhost:3000')
    const auth= useSelector(state => state.auth );
    
    const dispatch = useDispatch();
    const logoutUser = () => dispatch(logout());

    useEffect(()=>{
        if(auth.logged){
            conectarSocket();
        }
    },[auth,conectarSocket])

    useEffect(()=>{
        if(!auth.logged){
            desconectarSocket();
        }
    },[auth,desconectarSocket])

    useEffect(()=>{
        socket?.on('verifica-token', (resp) =>{
            if(resp?.token===false){logoutUser();}
            if(resp?.status===false){logoutUser();}
        });
    // eslint-disable-next-line
    },[socket])

    return (
        <SocketContext.Provider value={{ socket, online }}>
            { children }
        </SocketContext.Provider>
    )
}