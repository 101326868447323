import React from "react";

export const helperText = campos => {
  let mensaje = "";
  for (let index = 0; index < campos.length; index++) {
    if (campos.length === 1) {
      mensaje += campos[index];
      break;
    }
    if (index === campos.length - 1) {
      mensaje += " y " + campos[index];
    } else {
      mensaje += campos[index] + ", ";
    }
  }
  return mensaje;
};

export const varificaData = (arr, dtosLcEmpresa, excepciones = null, sustituirCampo = null) => {
  let estado = true;
  let campos = [];
  for (let index = 0; index < arr.length; index++) {//componentes con valores normales (false)
    for (const key in dtosLcEmpresa) {
      if (key === arr[index]) {
        if (
          dtosLcEmpresa[key].valor === "" ||
          dtosLcEmpresa[key].valor === "null" ||
          dtosLcEmpresa[key].valor === null ||
          dtosLcEmpresa[key].error === true
        ) {
          estado = false;
          campos.push(key);
        }
        break;
      }
    }
  }

  if (excepciones !== null) {//componentes con valores inversos (true)

    for (let index = 0; index < excepciones.length; index++) {
      for (const key in dtosLcEmpresa) {
        if (key === excepciones[index]) {
          if (dtosLcEmpresa[key].error === false) {
            estado = false;
            campos.push(key);
          }
          break;
        }
      }
    }

  }

  if (sustituirCampo != null) {
    campos = replaceString(campos, sustituirCampo);
  }

  campos = clearString(campos);

  return { estado, arr: campos };
};

export const replaceString = (arr, sustituirCampo) => {
  for (let index = 0; index < arr.length; index++) {
    for (let index2 = 0; index2 < sustituirCampo.length; index2++) {
      if (arr[index] === sustituirCampo[index2][0]) {
        arr[index] = sustituirCampo[index2][1];
      }
    }
  }
  return arr;
}

export const clearString = campos => {
  for (let index = 0; index < campos.length; index++) {
    campos[index] = campos[index].replace("_", " ");
  }
  return campos;
};

export const notification = (getNotificacion, valor = 0) => {
  if (getNotificacion.error === false) {
    return false;
  }
  if (getNotificacion.error === true) {
    return (
      <div className="alert alert-danger">
        <strong>Campos incorrectos: </strong>
        {getNotificacion.mensaje}
      </div>
    );
  }
  if (getNotificacion.error === null) {
    return (
      <div className="alert alert-warning">
        <strong>Procesando información... </strong>
        {getNotificacion.mensaje}
      </div>
    );
  }
};

