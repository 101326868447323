import decode from "jwt-decode";

export const autenticar = (token, success) => {
  let valido = false;
  try {
    if (decode(token) != null) {
      const { exp } = decode(token);
      if (Date.now() >= exp * 1000) {
        return false;
      }
      valido = true;
    }
  } catch (e) {
    return false;
  }

  return valido === true && success === true ? true : false;
};

export const decodificar = token => {
  try{
    return decode(token);
  }catch(e){
    return null;
  }
};

