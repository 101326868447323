import React, { Fragment, useState } from 'react';
//***DEPENDENCIAS***
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
//***TEMA***
import { colorPalettes } from "../Settings/theme";
//***REDUX***
import { useSelector } from "react-redux";
//***CORE***
import { withStyles, List, Divider, ListItemText, ListItem, Collapse, ListItemIcon, Typography, makeStyles, ListSubheader } from '../Utils/Constant/core';
//***ICONS***
import { ExpandLess, ExpandMore, AcUnitIcon } from '../Utils/Constant/icons';
import { dashRoutesAdmin, dashRoutesTecnico, dashRoutesCliente, dashRoutesDueño } from "../routes";
//***ESTILOS***
const useStyles = makeStyles(colorPalettes);
const styles = {
  hide: {
    display: 'none',
  },
};

const SmallerListItemIcon = withStyles({
  root: {
    "& .MuiSvgIcon-root": { fontSize: "1.5em" }
  }
})(ListItemIcon);

const ListItems = (props) => {
  //DECLARACION
  const [menu, setMenu] = useState({});
  const auth = useSelector(state => state.auth);
  const { history, open, except } = props;
  const classes = useStyles();

  //EVENTOS
  const handleClick = (item) => {
    let newData = { ...menu, [item]: !menu[item] };
    setMenu(newData);
  }
  const handleMenu = (children, level = 0) => {
    return children.map(({ children, name, layout, path, icon, iconColor, beforeLine, afterLine, subheader }) => {
      if (!children) {
        return (

          <Fragment key={`${name}-${path}`}>
            {beforeLine ? <Divider /> : ""}

            <List component="div" disablePadding key={name} aria-labelledby="nested-list-subheader"
              subheader={<ListSubheader className={clsx(open === false && props.classes.hide)} component="div" id="nested-list-subheader">{subheader}</ListSubheader>
              }>
              <ListItem button key={name} onClick={() => history.push(layout + path)}
              >
                {icon ? <SmallerListItemIcon className={classes.grayLetter + " " + classes[iconColor] + " " + classes.sizeIcon} >{icon}</SmallerListItemIcon> : ""}

                <ListItemText className={classes.grayLetter} primary={name} />

              </ListItem>
            </List>

            {afterLine ? <Divider /> : ""}
          </Fragment>
        )
      }
      return (
        <div key={name}>
          {beforeLine ? <Divider /> : ""}
          <ListItem button key={name} onClick={() => handleClick(name)} >
            {icon ? <SmallerListItemIcon className={classes.grayLetter + " " + classes[iconColor]} >{icon}</SmallerListItemIcon> : ""}
            {<ListItemText className={classes.grayLetter} primary={name} />} {menu[name] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={(menu[name]) ? true : false} timeout="auto" unmountOnExit >
            {handleMenu(children, 1)}
          </Collapse>
          {afterLine ? <Divider /> : ""}
        </div>
      )
    })
  }

  //FUNCIONES
  const functionTipo = (tipo) => {
    switch (tipo) {
      case "CLIENTE":
        return dashRoutesCliente.data;
      case "TECNICO":
        return dashRoutesTecnico.data;
      case "ADMINISTRADOR":
        return dashRoutesAdmin.data;
      case "DUEÑO":
        return dashRoutesDueño.data;
      default:
        break;
    }
  }
  const functionFiltro = (obj) => {
    if (except.indexOf(obj.name) === -1) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div>
      <List>
        <ListItem>
          {<SmallerListItemIcon className={classes.grayLetter}>{<AcUnitIcon color="primary" />}</SmallerListItemIcon>}
          <ListItemText primary={<Typography variant="body1">{`Menu ${auth.configuraciones.aliasEmpresa}`}</Typography>} className={classes.grayLetter} />
        </ListItem>
      </List>
      { handleMenu(functionTipo(auth.tipoUsuario).filter(functionFiltro))}
    </div>
  )
}

ListItems.propTypes = {
  open: PropTypes.bool.isRequired,
  except: PropTypes.array.isRequired,
};

export default withRouter(withStyles(styles)(ListItems));