import React, { useState, useEffect } from "react";
//***DEPENDENCIAS***
import Swal from "sweetalert2";
import { useMutation } from "@apollo/client";
//***COMPONENTES-CORE***
import {
  Grid,
  Typography,
  Button,
  StepLabel,
  Step,
  Stepper,
  TextField,
  FormHelperText,
  useTheme
} from '../Utils/Constant/core';
//***CONSTANTES***
import {
  rc_ColorlibConnector as ColorlibConnector,
  rc_ColorlibStepIcon as ColorlibStepIcon,
  rc_useStyles as useStyles,
} from "../Utils/Constant/step";
//***MUTATION***
import { DESBLOQUEAR_USUARIO } from "../Utils/Mutation/Usuarios";
//***UTILS***
import { rgxEmail, rgxPassword } from "../Utils/Constant/rgx";
//***HELPER***
import {
  varificaData,
  helperText,
  notification
} from "../Utils/Helpers/validate";

//FUNCIONES
function functionGetSteps() {
  return ["Correo electronico", "Contraseña", "Clave de empresa"];
}
function functionGetStepContent(step, datos, EventChangeCorreo, eventChangePassword, eventChangeClaveEmpresa) {
  switch (step) {
    case 0:
      return (
        <Grid item xs={12}>
          <TextField
            disabled={false}
            fullWidth
            onChange={EventChangeCorreo}
            value={datos.Recuperar.email.valor}
            error={datos.Recuperar.email.error}
            label="Email"
            id="correo_Rc"
            name="email"
          />
        </Grid>
      );
    case 1:
      return (
        <Grid item xs={12} >
          <TextField
            type="password"
            disabled={false}
            fullWidth
            label="Password"
            id="password_Rc"
            name="password"
            onChange={eventChangePassword}
            value={datos.Recuperar.password.valor}
            error={datos.Recuperar.password.error}
          />
          <FormHelperText id="standard-weight-helper-text">
            La contraseña debe contener una letra mayúscula, minúscula, numero,
            y un caracter especial ${"@#$%¡!*=<>|¿?-&()"}, tener como minimo 8
            caracteres y maximo 30 caracteres
          </FormHelperText>
        </Grid>
      );
    case 2:
      return (
        <Grid item xs={12}>
          <TextField
            disabled={false}
            fullWidth
            onChange={eventChangeClaveEmpresa}
            value={datos.Recuperar.claveAcceso.valor}
            error={datos.Recuperar.claveAcceso.error}
            label="Clave de empresa"
            id="claveAcceso_Rcc"
            name="claveAcceso"
          />
        </Grid>);
    default:
      return "Unknown step";
  }
}

export default function Desbloquear(props) {
  //DECLARACIONES
  const classes = useStyles();
  let theme= useTheme();
  let rgxPasswords = new RegExp(rgxPassword);
  let rgxCorreo = new RegExp(rgxEmail);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    Recuperar: {
      email: { valor: "", error: false },
      password: { valor: "", error: false },
      claveAcceso: { valor: "", error: false },
    }
  });
  const [getNotificacion, setNotificacion] = useState({
    error: false,
    mensaje: "",
  });
  const steps = functionGetSteps();

  //PETICIONES
  const [Desbloquear] = useMutation(DESBLOQUEAR_USUARIO);

  //EVENTOS
  const handleNext = (activeStep) => {
    if (activeStep === 0) {
      if (
        (data.Recuperar.email.valor !== null) &
        (data.Recuperar.email.valor !== "") &
        (data.Recuperar.email.error !== true)
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return null;
      }
    }
    if (activeStep === 1) {
      if (
        (data.Recuperar.password.valor !== null) &
        (data.Recuperar.password.valor !== "") &
        (data.Recuperar.password.error !== true)
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return null;
      }
    }

    if (activeStep === 2) {
      if (
        (data.Recuperar.claveAcceso.valor !== null) &
        (data.Recuperar.claveAcceso.valor !== "") &
        (data.Recuperar.claveAcceso.error !== true)
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        eventClickActualizar();
        return null;
      }
    }

  };
  const handleBack = (activeStep) => {
    if (activeStep === 0) {
      setData({
        ...data,
        Recuperar: {
          ...data.Recuperar,
          email: {
            valor: "",
            error: false,
          },
        },
      });
    }
    if (activeStep === 1) {
      setData({
        ...data,
        Recuperar: {
          ...data.Recuperar,
          password: {
            valor: "",
            error: false,
          },
        },
      });
    }
    if (activeStep === 2) {
      setData({
        ...data,
        Recuperar: {
          ...data.Recuperar,
          claveAcceso: {
            valor: "",
            error: false,
          },
        },
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  const eventChangeEmail = (event) => {
    let { name, value } = event.target;
    if (rgxCorreo.test(value)) {
      setData({
        ...data,
        Recuperar: {
          ...data.Recuperar,
          [name]: {
            valor: value,
            error: false,
          },
        },
      });
    } else {
      setData({
        ...data,
        Recuperar: {
          ...data.Recuperar,
          [name]: {
            valor: value,
            error: true,
          },
        },
      });
    }
  };
  const eventChangePassword = (event) => {
    let { name, value } = event.currentTarget;
    if (rgxPasswords.test(value)) {
      setData({
        ...data,
        Recuperar: {
          ...data.Recuperar,
          [name]: {
            valor: value,
            error: false,
          },
        },
      });
    } else {
      setData({
        ...data,
        Recuperar: {
          ...data.Recuperar,
          [name]: {
            valor: value,
            error: true,
          },
        },
      });
    }
  };
  const eventChangeClaveEmpresa = (event) => {
    let { name, value } = event.target;
    if (value !== null || value !== "") {
      setData({
        ...data,
        Recuperar: {
          ...data.Recuperar,
          [name]: {
            valor: value,
            error: false,
          },
        },
      });
    } else {
      setData({
        ...data,
        Recuperar: {
          ...data.Recuperar,
          [name]: {
            valor: value,
            error: true,
          },
        },
      });
    }
  };
  const eventClickActualizar = async () => {
    let campos = ["password", "email","claveAcceso"];
    let datos = varificaData(campos, data.Recuperar);
    let mensaje = helperText(datos.arr);
    if (datos.estado === false) {
      setNotificacion({
        ...getNotificacion,
        error: true,
        mensaje,
      });
    } else {
      setNotificacion({
        ...getNotificacion,
        error: false,
        mensaje: "",
      });
      await fetchDesbloquear();
      await props.data.salir();
    }
  };

  //FUNCION
  const functionLimpiarState = (event) => {
    setData({
      Recuperar: {
        email: { valor: "", error: false },
        password: { valor: "", error: false },
        claveAcceso: { valor: "", error: false },
      }
    });
    setNotificacion({
      error: false,
      mensaje: "",
    });
  };

  //API
  const fetchDesbloquear = async () => {
    //indicar proceso de la peticion
    setNotificacion({
      ...getNotificacion,
      error: null,
      mensaje: ""
    });

    try {
      const resp = await Desbloquear({
        variables: {
          email: data.Recuperar.email.valor,
          password: data.Recuperar.password.valor,
          claveAcceso: data.Recuperar.claveAcceso.valor,
        }
      });
      if (resp.data.unlockAccount.success === false) {
        Swal.fire({
          title:resp.data.unlockAccount.message,
          text:resp.data.unlockAccount.error,
          icon:"warning",
          confirmButtonColor: `${theme.palette.primary.main}`,
        });
      } else {
        Swal.fire({
          title:resp.data.unlockAccount.mensaje,
          text:"Se envio un correo para confirmar desbloqueo",
          icon:"success",
          confirmButtonColor: `${theme.palette.primary.main}`,
        });
      }
    } catch (e) {
      Swal.fire({
        title:"Operacion Fallida",
        text:"Error inesperado, vuelve intentalor más tarde",
        icon:"warning",
        confirmButtonColor: `${theme.palette.primary.main}`,
      });
    }

    //indicar proceso de la peticion
    setNotificacion({
      ...getNotificacion,
      error: false,
      mensaje: ""
    });
  }

  //CICLO DE VIDA
  useEffect(() => {
    handleReset();
    functionLimpiarState();
  }, [props.data]);

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid container spacing={3}>
        {activeStep === steps.length ? (
          <Grid item xs={12}>
            <Typography className={classes.instructions + " centrarTexto p1"}>
            </Typography>
          </Grid>
        ) : (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {notification(getNotificacion)}
                </Grid>
                {functionGetStepContent(
                  activeStep,
                  data,
                  eventChangeEmail,
                  eventChangePassword,
                  eventChangeClaveEmpresa
                )}
                <Grid
                  item
                  xs={12}
                  className="centrarTexto p1"
                >
                  <Button
                    disabled={activeStep === 0}
                    onClick={() => handleBack(activeStep)}
                    className={classes.button}
                  >
                    Volver
                </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleNext(activeStep)}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "TERMINAR" : "SIGUIENTE"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
      </Grid>
    </div>
  );
}
