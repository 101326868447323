import React, { useEffect } from "react";
//***DEPENDENCIAS***
import { useMutation } from "@apollo/client";
//***MUTATION***/
import { REENVIAR_TOKEN } from "./Utils/Mutation/Usuarios";
//***UTILS***
import { obtenerStateStorage } from "./Utils/Helpers/localStorage";
//***REDUX***/
import { useDispatch, useSelector } from "react-redux";
import { verificarToken } from "./Redux/Actions/authAction";
//**SOCKETS***
import { createHashHistory } from "history"; //createBrowserHistory
import { Redirect, Router, Switch } from "react-router-dom";
//****RUTAS*******************/
import { PublicRoute } from "./Utils/Estructura/PublicRoute";
import { PrivateRoute } from "./Utils/Estructura/PrivateRoute";
//***COMPONENTES***/
import Auth from "./Auth";
import Dashboard from "./Dashboard";
import SimpleCircularProgress from "./Utils/Generic/SimpleCircularProgress";
//***ESTILO***
import { DynamicColor } from "./Settings/theme";
import { MuiThemeProvider } from "@material-ui/core/styles";

const hist = createHashHistory();

export default function App() {
  //DECLARACION
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const verificarTokens = (data) => dispatch(verificarToken(data));
  const token = obtenerStateStorage('token')||"";

  //PETICIONES
  const [Login] = useMutation(REENVIAR_TOKEN);

  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await Login({
          variables: {
            token: token
          }
        });
        verificarTokens(resp);
      } catch (e) {
        let error={error:e?.message!=null?true:null};
        verificarTokens(error);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [dispatch])

  if (auth.checking) {
    return (<SimpleCircularProgress />)
  }

  return (
    <Router history={hist}>
      <MuiThemeProvider theme={DynamicColor(auth.theme)}>
        <Switch>
          <PublicRoute isAuthenticated={auth.logged} path="/auth" component={Auth} />
          <PrivateRoute isAuthenticated={auth.logged} path="/dashboard" component={Dashboard} tipoUsuario={auth.tipoUsuario} roles={["ADMINISTRADOR","DUEÑO","CLIENTE","TECNICO"]} />
          <Redirect to="/auth" />
        </Switch>
      </MuiThemeProvider>
    </Router>
  );
}

