import {
    LOGIN,
    LOGOUT,
    RENOVAR_TOKEN,
    RESTABLECER_TOKEN,
    UPDATE_THEME_USER,
    UPDATE_REGULACIONES,
    USER_ALERT,
    UPDATE_USER_DATA,
    UPDATE_NOMBRE_ALIAS_EMPRESA
} from "../Types";

const initialState = {
    idUsuario: null,
    checking: true,
    logged: false,
    name: null,
    email: null,
    tipoUsuario: null,
    theme: "Petunia",
    apellidoMaterno: null,
    apellidoPaterno: null,
    telefonoCasa: null,
    telefonoCelular: null,
    fechaNacimiento: null,
    configuraciones: {
        iva: null,
        serie: null,
        idEntidad: null,
        nombreEmpresa: null,
        aliasEmpresa: null,
        claveAcceso: null
    },
    alertas: {
        titulo: null,
        contenido: null,
        tipo: null
    },
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                idUsuario: action.payload.idUsuario,
                checking: false,
                logged: true,
                name: action.payload.nombre,
                email: action.payload.email,
                tipoUsuario: action.payload.tipoUsuario,
                theme: action.payload.theme,
                apellidoMaterno:  action.payload.apellidoMaterno,
                apellidoPaterno:  action.payload.apellidoPaterno,
                telefonoCasa:  action.payload.telefonoCasa,
                telefonoCelular:  action.payload.telefonoCelular,
                fechaNacimiento:  action.payload.fechaNacimiento,
                configuraciones: {
                    iva: action.payload.iva,
                    serie: action.payload.serie,
                    idEntidad: action.payload.idEntidad,
                    nombreEmpresa: action.payload.nombreEmpresa,
                    aliasEmpresa: action.payload.aliasEmpresa,
                    claveAcceso: action.payload.claveAcceso
                },
                alertas: {
                    titulo: null,
                    contenido: null,
                    tipo: null
                },
            }
        case LOGOUT:
            return {
                idUsuario: null,
                checking: false,
                logged: false,
                name: null,
                email: null,
                tipoUsuario: null,
                theme: "Petunia",
                apellidoMaterno: null,
                apellidoPaterno: null,
                telefonoCasa: null,
                telefonoCelular: null,
                fechaNacimiento: null,
                configuraciones: {
                    iva: null,
                    serie: null,
                    idEntidad: null,
                    nombreEmpresa: null,
                    aliasEmpresa: null,
                    claveAcceso: null
                },
                alertas: {
                    titulo: null,
                    contenido: null,
                    tipo:null
                },
            }
        case RENOVAR_TOKEN:
            return {
                idUsuario: action.payload.idUsuario,
                checking: false,
                logged: true,
                name: action.payload.nombre,
                email: action.payload.email,
                tipoUsuario: action.payload.tipoUsuario,
                theme: action.payload.theme,
                apellidoMaterno:  action.payload.apellidoMaterno,
                apellidoPaterno:  action.payload.apellidoPaterno,
                telefonoCasa:  action.payload.telefonoCasa,
                telefonoCelular:  action.payload.telefonoCelular,
                fechaNacimiento:  action.payload.fechaNacimiento,
                configuraciones: {
                    iva: action.payload.iva,
                    serie: action.payload.serie,
                    idEntidad: action.payload.idEntidad,
                    nombreEmpresa: action.payload.nombreEmpresa,
                    aliasEmpresa: action.payload.aliasEmpresa,
                    claveAcceso: action.payload.claveAcceso
                },
                alertas: {
                    titulo: null,
                    contenido: null,
                    tipo:null
                }
            }
        case RESTABLECER_TOKEN:
            return {
                idUsuario: null,
                checking: false,
                logged: false,
                name: null,
                email: null,
                tipoUsuario: null,
                theme: "Petunia",
                apellidoMaterno: null,
                apellidoPaterno: null,
                telefonoCasa: null,
                telefonoCelular: null,
                fechaNacimiento: null,
                configuraciones: {
                    iva: null,
                    serie: null,
                    idEntidad: null,
                    nombreEmpresa: null,
                    aliasEmpresa: null,
                    claveAcceso: null
                },
                alertas: {
                    titulo: null,
                    contenido: null,
                    tipo: null
                },
                
            }
        case UPDATE_THEME_USER:
            return action.payload;
        case UPDATE_REGULACIONES:
            return action.payload;
        case UPDATE_USER_DATA:
            return action.payload;
        case UPDATE_NOMBRE_ALIAS_EMPRESA:
            return action.payload;
        case USER_ALERT:
            return {
                idUsuario: null,
                checking: false,
                logged: false,
                name: null,
                email: null,
                tipoUsuario: null,
                theme: "Petunia",
                apellidoMaterno: null,
                apellidoPaterno: null,
                telefonoCasa: null,
                telefonoCelular: null,
                fechaNacimiento: null,
                configuraciones: {
                    iva: null,
                    serie: null,
                    idEntidad: null,
                    nombreEmpresa: null,
                    aliasEmpresa: null,
                    claveAcceso: null
                },
                alertas: {
                    titulo: action.payload.titulo,
                    contenido: action.payload.contenido,
                    tipo: action.payload.tipo
                }
            }
        default:
            return state;
    }
}