import React from 'react';
//*** DEPEDENCIAS***
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SocketProvider } from "./Utils/Context/SocketContext";
//***HEPERS***
import { obtenerStateStorage } from "./Utils/Helpers/localStorage";
//***REDUX***
import { Provider } from "react-redux";
import { store } from "./Redux/store";
//***CONFIGURACION-APOLLO***
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink} from "@apollo/client";
import {setContext } from "@apollo/client/link/context";

const { REACT_APP_GRAPHQL_URL } = process.env;
const httpLink = createHttpLink({
  uri: REACT_APP_GRAPHQL_URL||`http://localhost:3000/graphql/`,
});
const authLink = setContext((_, { headers }) => {
  const token = obtenerStateStorage('token') || ""
  return {
    headers: {
      ...headers,
      authorization: token 
    }
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
  fetchOptions: {
    credentials: 'include'
  },
  onError: ({ networkError, graphQLErrros }) => {
    console.log(networkError, graphQLErrros);
  }
});

ReactDOM.render(

  <Provider store={store}>
    <ApolloProvider client={client}>
      <SocketProvider>
        <App />
      </SocketProvider>
    </ApolloProvider>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// import reportWebVitals from './reportWebVitals';
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
