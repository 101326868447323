import React from "react";
//***DEPENDENCIAS***/
import { Route,Redirect } from "react-router-dom";

export const PublicRoute = ({
   isAuthenticated,
   component: Component,
   history,
   ...rest
}) => {
    return (
        <Route { ...rest }
            component={ (props) => (
                ( !isAuthenticated )
                    ? <Component { ...props } />
                    : <Redirect to="/dashboard"/>
            )} 
        />
    )
}