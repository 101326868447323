import React, { useState, useEffect } from 'react';
//***DEPENDENCIAS***/
import Swal from "sweetalert2";
import { useMutation } from "@apollo/client";
//***REDUX***/
import { useDispatch, useSelector } from "react-redux";
import { login } from "../Redux/Actions/authAction";
//***ROUTER***/
import { withRouter } from "react-router-dom";
//***COMPONENTES***
import Copyright from '../Utils/Generic/Copyright';
import ModalAvisoLarge from "../Utils/Generic/ModalAvisoLarge";
import Desbloquear from "./Desbloquear";
import RecoverPassword from "./RecoverPassword";
import SimpleSnackbars from "../Utils/Generic/SimpleSnackbars";
import ModalMobile from "../Utils/Generic/ModalMobile";
//***CORE***
import {
  Avatar, Button, CssBaseline, TextField,
  Link, Paper, Box, Grid, Typography, makeStyles,
  useTheme, Hidden
} from '../Utils/Constant/core';
//***MUTATION***/
import { AUTENTICAR_USUARIO } from "../Utils/Mutation/Usuarios";
//***HOOKS***/
import { useForm } from "../Utils/Hooks/form";
//***UTILS***/
import { rgxPassword } from "../Utils/Constant/rgx";
import { varificaData, helperText, notification } from "../Utils/Helpers/validate";
import { guardarStateStorage } from "../Utils/Helpers/localStorage";
//***ICOS***
import { LockOutlinedIcon } from '../Utils/Constant/icons';
//***IMG***
import '../assets/Css/Images.css';
//***STYLE***
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignInSide(props) {

  //DECLARACION
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const auth = data => dispatch(login(data));
  const authData = useSelector(state => state.auth);
  //STATE
  const [getNotificacion, setNotificacion] = useState({
    error: false,
    mensaje: ""
  });
  const [getBotton, setBotton] = useState(false);
  const [getOpciones, setOpciones] = useState({ bloqueo: false, recuperar: false });
  const [formState, handleInputChange] = useForm({
    input: {
      email: {
        rgx: '',
        ref: '',
        valor: '',
        options: {
          file: false,
          img: false,
          rgx: true
        }
      },
      password: {
        valor: '',
        error: false,
        rgx: rgxPassword,
        ref: '',
        options: {
          file: false,
          img: false,
          rgx: true
        }
      },
      claveAcceso: {
        rgx: '',
        ref: '',
        valor: '',
        options: {
          file: false,
          img: false,
          rgx: true
        }
      }
    }
  });
  //DESTRUCTURING 
  const { email, password, claveAcceso } = formState.input;
  const { history } = props;
  //PETICIONES
  const [Login] = useMutation(AUTENTICAR_USUARIO);
  //EVENTOS
  const handleOnClickClaveEmpresa = () => {

    if (formState.input.password.valor !== "" && formState.input.password.error !== true && formState.input.email.valor !== "" && formState.input.email.error !== true) {
      Swal.fire({
        title: 'Clave de empresa',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonColor: `${theme.palette.primary.main}`,
        confirmButtonText: 'Confirmar',
        showLoaderOnConfirm: true,
        preConfirm: (data) => {
          formState.input.claveAcceso.valor = data;
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          handleOnClickLogin();
        }
      })
    }
  }
  const handleOnClickLogin = async (e) => {
    setBotton(true);
    let campos = ["email", "password", "claveAcceso"];
    let datos = varificaData(campos, formState.input, null, [["email", "Email"], ["password", "Contraseña"], ["claveAcceso", "Clave de empresa"]]);
    let mensaje = helperText(datos.arr);
    if (datos.estado === false) {
      setNotificacion({
        ...getNotificacion,
        error: true,
        mensaje
      });
      setBotton(false);
    } else {
      setNotificacion({
        ...getNotificacion,
        error: false,
        mensaje: ""
      });
      await fetchLogin();
      setBotton(false);
    }
    //Limpio el sweat alert
    formState.input.claveAcceso.valor = "";
  }
  const handleOnClickOpenBloqueo = () => {
    setOpciones((prevState) => ({
      ...prevState, bloqueo: true
    }))
  }
  const handleOnClickOpenRecuperar = () => {
    setOpciones((prevState) => ({
      ...prevState, recuperar: true
    }))
  }
  const handleOnClickCloseBloqueo = () => {
    setOpciones((prevState) => ({
      ...prevState, bloqueo: false
    }))
  }
  const handleOnClickCloseRecuperar = () => {
    setOpciones((prevState) => ({
      ...prevState, recuperar: false
    }))
  }

  //FUNCIONES
  const functionLogin = async (resp) => {
    await guardarStateStorage(resp.login.response.token, "token")
    await auth({
      nombre: resp.login.nombre,
      tipoUsuario: resp.login.tipoUsuario,
      enlinea: resp.login.enlinea,
      email: resp.login.email,
      idUsuario: resp.login.idUsuario,
      token: resp.login.response.token,
      theme: resp.login.response.theme,
      iva: resp.login.configuracion.iva,
      serie: resp.login.configuracion.serie,
      idEntidad: resp.login.configuracion.idEntidad,
      nombreEmpresa: resp.login.configuracion.nombreEmpresa,
      aliasEmpresa: resp.login.configuracion.aliasEmpresa,
      claveAcceso: resp.login.configuracion.claveAcceso,
      apellidoMaterno: resp.login.apellidoMaterno,
      apellidoPaterno: resp.login.apellidoPaterno,
      telefonoCasa: resp.login.telefonoCasa,
      telefonoCelular: resp.login.telefonoCelular,
      fechaNacimiento: resp.login.fechaNacimiento,
    });
    history.push(`/dashboard`);

  }
  const functionConvertirSTB = valor => {
    return valor === false ? false : true;
  };

  //API
  const fetchLogin = async () => {
    //indicar proceso de la peticion
    setNotificacion({
      ...getNotificacion,
      error: null,
      mensaje: ""
    });

    try {
      const resp = await Login({
        variables: {
          email: email.valor,
          password: password.valor,
          claveAcceso: claveAcceso.valor
        }
      });
      if (resp.data.login.response.success === false) {
        Swal.fire({
          title: resp.data.login.response.message,
          text: resp.data.login.response.error,
          icon: "warning",
          confirmButtonColor: `${theme.palette.primary.main}`,
        });
      } else {
        functionLogin(resp.data)
      }
    } catch (e) {
      Swal.fire({
        title: "Operacion Fallida",
        text: "Error inesperado, vuelve intentalor más tarde",
        icon: "warning",
        confirmButtonColor: `${theme.palette.primary.main}`,
      });
    }

    //indicar proceso de la peticion
    setNotificacion({
      ...getNotificacion,
      error: false,
      mensaje: ""
    });

  };

  //OBJETOS
  const objBloquearCuenta = {
    data: { abrir: true, salir: () => handleOnClickCloseBloqueo() },
    datos: { abrir: functionConvertirSTB(getOpciones.bloqueo) },
    evtClose: () => handleOnClickCloseBloqueo(),
    personalizar: { titulo: "DESBLOQUEAR CUENTA" },
    componente: Desbloquear,
    cantidadOpciones: 1,
    habilitarBoton: true,
    btnNombre: "GUARDAR"
  }
  const objNuevaPassword = {
    data: { abrir: true, salir: () => handleOnClickCloseRecuperar() },
    datos: { abrir: functionConvertirSTB(getOpciones.recuperar) },
    evtClose: () => handleOnClickCloseRecuperar(),
    personalizar: { titulo: "NUEVA CONTRASEÑA" },
    componente: RecoverPassword,
    cantidadOpciones: 1,
    habilitarBoton: true,
    btnNombre: "GUARDAR"
  }

  //CICLO DE VIDA
  useEffect(() => {
    return () => {
      setNotificacion({
        error: false,
        mensaje: ""
      });
      setBotton(false);
    }
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <Hidden xsDown> {/* Disponible para pc */}
        <ModalAvisoLarge {...objBloquearCuenta} />
        <ModalAvisoLarge {...objNuevaPassword} />
      </Hidden>
      <Hidden smUp> {/* Disponible para mobile */}
        <ModalMobile {...objBloquearCuenta} personalizar={{ titulo: "Desbloquear" }} />
        <ModalMobile {...objNuevaPassword} personalizar={{ titulo: "Nueva contraseña" }} />
      </Hidden>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={`imageLogin`} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar} style={{ backgroundColor: theme.palette.primary.main }}>
            <LockOutlinedIcon ></LockOutlinedIcon>
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar sesión
          </Typography>
          <br />
          {notification(getNotificacion)}
          {authData.alertas.tipo != null
            ? <SimpleSnackbars tipo={authData.alertas.tipo} titulo={authData.alertas.titulo} contenido={authData.alertas.contenido} />
            : ""
          }
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              error={email.error}
              value={email.valor}
              autoComplete="email"
              inputProps={{
                onChange: handleInputChange
              }}
              name="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              value={password.valor}
              error={password.error}
              inputProps={{
                onChange: handleInputChange
              }}
              id="password"
              autoComplete="current-password"
            />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleOnClickClaveEmpresa}
              disabled={getBotton}
            >
              Entrar
            </Button>
            <Grid container>
              <Grid item xs>
                <Link variant="body2" onClick={handleOnClickOpenRecuperar}>
                  ¿Olvidastes tu contraseña?
                </Link>
              </Grid>
              <Grid item>
                <Link variant="body2" onClick={handleOnClickOpenBloqueo} >
                  Desbloquear cuenta
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright name="WhiteCat" link="" />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default withRouter(SignInSide);