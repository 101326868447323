import React, { Fragment } from "react";
//***CORE***
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from '@material-ui/core/Button';
//***HELERS***
import { notification } from "../Helpers/validate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


//function ModalAvisoLarge(props) {
const ModalAvisoLarge = (props) => {
  let opcionesBotones = cantidadOpciones => {
    if (cantidadOpciones === 1) {
      return (
        <Button onClick={() => props.evtClose()} color="primary">
          CERRAR
        </Button>
      );
    } else {
      return (
        <>
          <Button onClick={() => props.evtClose()} color="secondary">
            CERRAR
          </Button>
          <Button onClick={() => props.evtClick()} color="primary" disabled={props.habilitarBoton}>
            GUARDAR
          </Button>
        </>
      );
    }
  };
  return (
    <Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={props.datos.abrir}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.evtClose()}
        aria-labelledby="large-modal-slide-title"
        aria-describedby="large-modal-slide-description"
      >
        <DialogTitle
          id="large-modal-slide-title"
          disableTypography
        >
          <h4>{props.personalizar.titulo}</h4>
          <hr />
          {notification(props.faltanCampos || "")}
        </DialogTitle>
        <DialogContent
          id="large-modal-slide-description"
        >
          <props.componente data={props.data} />
        </DialogContent>
        <DialogActions>
          {opcionesBotones(props.cantidadOpciones)}
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default ModalAvisoLarge;