export const ftStringToDate=campo=>{
    if(typeof campo==="string") return new Date(campo);
    return campo;
}

export const ftFechaValida=(fecha)=>(isNaN(ftStringToDate(fecha).getFullYear()));

export const ftFechaRangoValida=(fecha)=> (1753<=ftStringToDate(fecha).getFullYear() && 
 ftStringToDate(fecha).getFullYear()<=2077);

export const ftFechaRangoValidaHoy=(fecha)=> (1753<=ftStringToDate(fecha).getFullYear()&& 
ftStringToDate(fecha).getFullYear()<=new Date().getFullYear());
