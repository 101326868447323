import React from 'react';
//***DEPENDENCIAS***
import PropTypes from 'prop-types';
//***CORE***
import { Link, Typography } from '../Constant/core';

function Copyright(props) {
  //DECLARACION
  const { name, link } = props;

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href={link}>
        {name}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

Copyright.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default Copyright;