import React from "react";
//***DEPENDENCIAS***/
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = ({ 
  component: Component,
  roles,
  tipoUsuario,
  isAuthenticated,
   ...rest 
  }
  ) => {
  return (
    <Route {...rest}  render={ (props) =>{
        if (roles && roles.indexOf(tipoUsuario) === -1) {
          return <Redirect to="/auth" />
        }
        if( isAuthenticated ){
          return <Component { ...props } />
        }else{
          return <Redirect to="/auth" />
        }  
    }} />
  );
};
