import React from 'react';
//***DEPENDENCIAS***
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
//***TEMA***
import { colorPalettes } from "../../Settings/theme";
//***CORE***
import { Typography, MenuItem, Menu, ListItemIcon, makeStyles, Tooltip, IconButton, withStyles, ListItemText } from '../Constant/core';
//***ESTILOS***
const useStyles = makeStyles(colorPalettes);
const styles = {
  spacingSimpleMenu:{
    margin: "10px 15px 0",
    width: "auto"
  }, 
};
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.042), 0px 3px 14px 2px rgba(0, 0, 0, 0.036)'
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const SmallerListItemIcon = withStyles({
  root: {
    "& .MuiSvgIcon-root": { fontSize: "1.5em" }
  }
})(ListItemIcon);

function SimpleMenu(props) {

  //DECLARACION
  const { history, icon, name, opciones, user } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  //EVENTOS
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //FUNCION
  const functionMenuItem = (arr) => {
    // eslint-disable-next-line
    return arr.map(({ path, layout, name, evt, type, icon }) => {
      if (type === "event") {
        return (
          <StyledMenuItem className={classes.spacingSimpleMenu} key={name} onClick={() => { setAnchorEl(null); evt() }}>
            <SmallerListItemIcon>
              {icon}
            </SmallerListItemIcon>
            <ListItemText primary={name} />
          </StyledMenuItem>
        )
      }
      if (type === "router") {
        return (
          <StyledMenuItem className={classes.spacingSimpleMenu} key={name} onClick={() => { setAnchorEl(null); history.push(layout + path) }} >
            <SmallerListItemIcon>
              {icon}
            </SmallerListItemIcon>
            <ListItemText primary={name} />
          </StyledMenuItem>
        )
      }
    })
  }

  return (
    <div>
      <Tooltip title={name}>
        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} color="inherit">
          {icon} {` `}<Typography variant="body1">{`${user}`}</Typography>
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {functionMenuItem(opciones)}
      </StyledMenu>
    </div>
  );
}

SimpleMenu.propTypes = {
  name: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  opciones: PropTypes.array.isRequired,
};

export default withRouter(withStyles(styles)(SimpleMenu));
