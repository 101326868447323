import { gql } from "@apollo/client";

export const ADD_USER_PRIMARY=gql`
mutation createPrimaryUsers(
  $nombre: String!
  $apellidoMaterno: String!
  $apellidoPaterno: String!
  $telefonoCasa: String
  $telefonoCelular: String
  $fechaNacimiento: Date!
  $email: String!
  $password: String!
  $tipoUsuario: String!
  $nombreEntidad: String!
  $aliasEntidad: String!
  $estatus: String
) {
  createPrimaryUsers(
    input: {
      estatus: $estatus
      nombre: $nombre
      apellidoMaterno: $apellidoMaterno
      apellidoPaterno: $apellidoPaterno
      telefonoCasa: $telefonoCasa
      telefonoCelular: $telefonoCelular
      fechaNacimiento: $fechaNacimiento
      email: $email
      tipoUsuario: $tipoUsuario
      password: $password
      nombreEntidad: $nombreEntidad
      aliasEntidad: $aliasEntidad
    }
  ) {
    success
    message
    error
    token
    data
    theme
  }
}
`;

export const ADD_USER_SECONDARY=gql`
mutation createSecondaryUsers(
  $nombre: String
  $apellidoMaterno: String
  $apellidoPaterno: String
  $telefonoCasa: String
  $telefonoCelular: String
  $fechaNacimiento: Date
  $email: String
  $password: String
  $tipoUsuario: String
  $idEntidad: ID
  $estatus: String
  $claveAcceso: String
  $nombreEmpresa: String
) {
  createSecondaryUsers(
    input: {
      estatus: $estatus
      nombre: $nombre
      apellidoMaterno: $apellidoMaterno
      apellidoPaterno: $apellidoPaterno
      telefonoCasa: $telefonoCasa
      telefonoCelular: $telefonoCelular
      fechaNacimiento: $fechaNacimiento
      email: $email
      tipoUsuario: $tipoUsuario
      password: $password
      idEntidad: $idEntidad
      claveAcceso: $claveAcceso
      nombreEmpresa: $nombreEmpresa
    }
  ) {
    success
    message
    error
    token
    data
    theme
  }
}
`;

export const UPDATE_USERS=gql`
mutation updateUsers(
  $nombre: String
  $apellidoMaterno: String
  $apellidoPaterno: String
  $telefonoCasa: String
  $telefonoCelular: String
  $fechaNacimiento: String
  $email: String
  $estatus: String
  $claveAcceso: String
  $tipoUsuario: String
  $idUsuario: ID
) {
  updateUsers(
    input: {
      idUsuario: $idUsuario
      tipoUsuario: $tipoUsuario
      nombre: $nombre
      apellidoMaterno: $apellidoMaterno
      apellidoPaterno: $apellidoPaterno
      telefonoCasa: $telefonoCasa
      telefonoCelular: $telefonoCelular
      fechaNacimiento: $fechaNacimiento
      email: $email
      estatus: $estatus
      claveAcceso: $claveAcceso
    }
  ) {
    success
    message
    error
    token
    data
    theme
  }
}
`;

export const UPDATE_THEME=gql`
mutation themesUser($theme: String!, $email: String!, $claveAcceso: String!) {
  themesUser(
    input: { email: $email, theme: $theme, claveAcceso: $claveAcceso }
  ) {
    response
    message
    error
    token
    success
    data
    theme
  }
}
`;

export const REENVIAR_TOKEN=gql`
mutation renewTokenUser($token: String!) {
  renewTokenUser(input: { token: $token }) {
    nombre
    idUsuario
    tipoUsuario
    enlinea
    email
    apellidoMaterno
    apellidoPaterno
    telefonoCasa
    telefonoCelular
    fechaNacimiento
    configuracion {
      iva
      serie
      idEntidad
      nombreEmpresa
      aliasEmpresa
      claveAcceso
    }
    response {
      success
      message
      error
      response
      token
      data
      theme
    }
    
  }
}
`;

export const RECUPERAR_CONTRASEÑA=gql`
mutation recoverPassword(
  $password: String!
  $email: String!
  $claveAcceso: String!
) {
  recoverPassword(
    input: { email: $email, password: $password, claveAcceso: $claveAcceso }
  ) {
    response
    message
    error
    token
    success
    data
  }
}
`;

export const DESBLOQUEAR_USUARIO=gql`
mutation unlockAccount(
  $password: String!
  $email: String!
  $claveAcceso: String!
) {
  unlockAccount(
    input: { email: $email, password: $password, claveAcceso: $claveAcceso }
  ) {
    response
    message
    error
    token
    data
    success
  }
}
`;

export const AUTENTICAR_USUARIO=gql`
mutation autenticarUsuario(
  $email: String!
  $password: String!
  $claveAcceso: String!
) {
  login(
    input: { email: $email, password: $password, claveAcceso: $claveAcceso }
  ) {
    nombre
    idUsuario
    tipoUsuario
    enlinea
    email
    apellidoMaterno
    apellidoPaterno
    telefonoCasa
    telefonoCelular
    fechaNacimiento
    configuracion {
      iva
      serie
      idEntidad
      nombreEmpresa
      aliasEmpresa
      claveAcceso
    }
    response {
      success
      message
      error
      response
      token
      data
      theme
    }
  }
}
`;