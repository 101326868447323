import React from "react";
//***CORE***
import { Grid, CircularProgress,useTheme } from "../Constant/core";
//***STYLE***

export default function Loading() {
    let theme=useTheme();
    return (
        <Grid container direction="row" justifyContent="center" alignItems="center"
            style={{ textAlign: "center", position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <Grid item md={12} sm={12} className="w100">
                <CircularProgress style={{ color: theme.palette.primary.main }} />
            </Grid>
        </Grid>
    );
}