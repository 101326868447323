const rgxTel=/^[0-9]{10,13}$/;
const rgxCp=/^[0-9]{5}$/;
const rgxNumeroRango=/^[0-9]{1,3}$/;
const rgxPrecios=/^\d+(.\d{1,2})?$/;
const rgxColors=/#?[0-9A-Fa-f]{6}/;
const rgxLetras = /^(\s*[a-zA-ZÀ-ÿ.]+\s*)+$/;
const rgxPassword=/^(?=(.*[\d]){1,})(?=(.*[a-zñ]){1,})(?=(.*[A-ZÑ]){1,})(?=(.*[@#$%¡!*=<>()|¿?&-]){1,})(?:[\da-zA-ZÑñ=@#$%¡!*<>()|¿?&-]){8,30}$/;
//eslint-disable-next-line 
const rgxEmail=/^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/;
const rgxPorcentajes=/^(100|([0-9]{1,2}|[0-9]{1,2}\.[0-9]{1,10}))$/;
export {rgxEmail,rgxCp,rgxTel,rgxLetras,rgxPassword,rgxNumeroRango,rgxPorcentajes,rgxPrecios,rgxColors};

