import { useEffect, useState, useCallback } from 'react';
import io from 'socket.io-client';
//***HELPER***
import { obtenerStateStorage } from "../Helpers/localStorage";
//***REDUX***
import { useSelector } from "react-redux";

export const useSocket = ( serverPath ) => {
    
    const [ socket, setSocket ] = useState(null);
    const [ online, setOnline ] = useState(false);
    const auth= useSelector(state => state.auth );
    const token = obtenerStateStorage('token');
    
    const conectarSocket = useCallback(()=>{
        const socketTemp= io.connect( serverPath, {transports:['websocket'], autoConnect:true,forceNew:true,query:{"x-token":token}});
        setSocket(socketTemp);
        // eslint-disable-next-line 
    },[serverPath,auth]);

    const desconectarSocket = useCallback(()=>{
        socket?.disconnect();
    },[socket]);

    useEffect(() => {
        setOnline( socket?.connected );
    }, [socket])

    useEffect(() => {
        socket?.on('connect', () => setOnline( true ));
    }, [ socket ])

    useEffect(() => {
        socket?.on('disconnect', () => setOnline( false ));
    }, [ socket ])

    return {
        socket,
        online,
        conectarSocket,
        desconectarSocket
    }
}