//**COMPONENTES***
import { createTheme } from '@material-ui/core/styles';
import NovaScript from "../assets/Fonts/NovaScript.ttf";
import Brushtip_c from "../assets/Fonts/Brushtip.ttf";
//***ESTILOS***
import "../assets/Css/theme.css";

//TIPOGRAFIAS
const NOVASCRIPT = {
  fontFamily: 'novaScript',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${NovaScript}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
// eslint-disable-next-line 
const BRUSHTIP = {
  fontFamily: 'Brushtip_c',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${Brushtip_c}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

//PALETAS DE COLORES
const SECONDARY= {
  light: '#ff7961',
  main: '#f44336',
  dark: '#ba000d',
  contrastText: '#000',
};
const ERROR= {
  light: '#ff7961',
  main: '#f44336',
  dark: '#ba000d',
  contrastText: '#000',
};
const PETUNIA={
  light: '#ba68c8',
  main: '#ab47bc',
  dark: '#aa00ff',
  contrastText: '#fff',
};
const LAVANDA={
  light: '#B39CD0',
  main: '#845EC2',
  dark: '#492986',
  contrastText: '#fff',
};
const CRISANTEMO={
  light: '#9DEC98',
  main: '#2E7D32',
  dark: '#00550B',
  contrastText: '#fff',
}
const GLADIOLUS={
  light: '#55C6AE',
  main: '#008F7A',
  dark: '#005947',
  contrastText: '#fff',
}
const MALEZA={
  light: '#949DAB',
  main: '#616A77',
  dark: '#3D4856',
  contrastText: '#fff',
}
const PLAGA={
  light: '#7C7484',
  main: '#4B4453',
  dark: '#342E3C',
  contrastText: '#fff',
}

//FUNCION DINAMICA DE COLOR
export const DynamicColor  = (name) =>{

  let color = "";

  switch (name) {
    case "PETUNIA":
      color = PETUNIA;
      break;
    case "LAVANDA":
      color = LAVANDA;
      break;
    case "CRISANTEMO":
      color = CRISANTEMO;
      break;
    case "GLADIOLUS":
      color = GLADIOLUS;
      break;
    case "MALEZA":
      color = MALEZA;
      break;
    case "PLAGA":
      color = PLAGA;
      break;
    default:
      color = PETUNIA;
      break;
  }
    
   return createTheme({
    typography: {
      fontFamily: 'novaScript', //TIPOGRAFIA GENERAL
    },
    overrides: {
      MuiInput: {
        underline: {            //BORDES DE COLOR
          '&:before': {
              borderBottom: `2px solid ${[name].main}`
          },
          '&:after': {
              borderBottom: `2px solid ${[name].main}`
          }}
    },
      MuiInputLabel: {          //FOCUS
        root: { 
          /*
           "&$focused": { 
           color: `${PRIMARY.main}`
           },
          */
       }
      },
      MuiCssBaseline: {        //TIPOGRAFIA GENERAL
        '@global': {
          '@font-face': [NOVASCRIPT],
        },
      },
    },
    palette: {                //PALETA DE COLORES
      primary: color,
      secondary: SECONDARY,
      error:ERROR
    },
  })

}
//FUNCION DE COLORES GLOBALES
export const colorPalettes = (theme)=>({
  primaryColor:{
    color:theme.palette.primary.main
  },
  grayLetter:{
    color: "#616A77!important"
  },
  cloudBlueColor: {
    color: `#689AEB!important`
  },
  redColor: {
    color: `#F5365C!important`
  },
  orangeColor: {
    color: `#FB6340!important`
  },
  yellowColor: {
    color: `#FFD600!important`
  },
  lightBlueColor: {
    color: `#11CFF2!important`
  },
  pinkColor:{
    color: `#F3A4B5!important`
  },
  purpleColor:{
    color: `#ab47bc!important`
  },
  greenColor:{
    color:`#2e7d32!important`
  },
  greyColor:{
    color:`#616A77!important`
  },
  metallicGrayColor:{
    color:`#4B4453!important`
  },
  lilacColor:{
    color:`#845EC2!important`
  },
  mintColor:{
    color:`#00C9A7!important`
  },
  emeraldColor:{
    color:`#008F7A!important`
  },
  metallicGrayBg:{
    background:`#4B4453!important`
  },
  emeraldBg:{
    background:`#008F7A!important`
  },
  mintBg:{
    background:`#00C9A7!important`
  },
  lilacBg:{
    backgroundColor:`#845EC2!important`
  },
  greyBg:{
    backgroundColor:`#616A77!important`
  },
  greenBg:{
    backgroundColor:`#2e7d32!important`
  },
  cloudBlueBg: {
    backgroundColor: `#689AEB!important`
  },
  redBg: {
    backgroundColor: `#F5365C!important`
  },
  orangeBg: {
    backgroundColor: `#FB6340!important`
  },
  yellowBg: {
    backgroundColor: `#FFD600!important`
  },
  lightBlueBg: {
    backgroundColor: `#11CFF2!important`
  },
  pinkBg:{
    backgroundColor: `#F3A4B5!important`
  },
  purpleBg:{
    backgroundColor: `#ab47bc!important`
  },
})

