import React, { useState, useEffect, useContext, Suspense, lazy } from 'react';
//***DEPENDENCIAS***
import clsx from 'clsx';
//***ROUTER***
import { Switch, Redirect, HashRouter } from "react-router-dom"; //BrowserRouter
import { dashRoutesAdmin, dashRoutesTecnico, dashRoutesCliente, dashRoutesDueño } from "../routes";
import { PrivateRoute } from "../Utils/Estructura/PrivateRoute";
//***REDUX***
import { useDispatch,useSelector } from "react-redux";
import { logout } from "../Redux/Actions/authAction";
// ***COMPONENTES***
import ListItems from './ListItems';
import SimpleMenu from "../Utils/Generic/SimpleMenu";
import SimpleCircularProgress from "../Utils/Generic/SimpleCircularProgress";
//***CORE***
import {
  makeStyles, CssBaseline, Drawer, AppBar, Toolbar,
  Typography, IconButton, Container, Box, Hidden

} from '../Utils/Constant/core';
//***UTILS***
import { obtenerStateStorage } from "../Utils/Helpers/localStorage";
import { decodificar } from "../Utils/Helpers/autenticar";
import { SocketContext } from "../Utils/Context/SocketContext";
//***ICONS***
import { SettingsIcon, PersonIcon, MenuIcon, ChevronLeftIcon, MeetingRoomIcon } from '../Utils/Constant/icons';
//***CONSTANTES***
const Copyright = lazy(() => import("../Utils/Generic/Copyright"));
const SimpleBreadCrumb = lazy(() => import("../Utils/Generic/SimpleBreadCrumb"));
//***STYLES***
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaperMobile:{
    width: drawerWidth,
  },
  drawerMobile: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  sombrasMenu: {
    boxShadow: "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  transparencia:{
    backdropFilter:"blur(5px)",
    backgroundColor:`${theme.palette.primary.main}95`
  },
  hide: {
    display: 'none',
  },
}));

export default function Dashboard() {

  //DECLARACION
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { socket } = useContext(SocketContext);
  const logoutUser = () => dispatch(logout());

  //EVENTOS
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleOnClickLogout = () => {
    logoutUser()
  }

  //FUNCION
  const functionGetRoutes = routes => {
    return routes.map(({key, children, collapse,layout,path,component}) => {
      if (collapse) {
        return functionGetRoutes(children);
      }
      if (layout === "/dashboard"  ) {
        return (
          <PrivateRoute key={key+path} isAuthenticated={auth.logged} path={layout + path} component={component} tipoUsuario={auth.tipoUsuario} roles={["ADMINISTRADOR","DUEÑO","CLIENTE","TECNICO"]} />
        );
      } else {
        return null;
      }
    });
  };
  const functionTipo = (tipo) => {
    switch (tipo) {
      case "CLIENTE":
        return dashRoutesCliente.data;
      case "TECNICO":
        return dashRoutesTecnico.data;
      case "ADMINISTRADOR":
        return dashRoutesAdmin.data;
      case "DUEÑO":
        return dashRoutesDueño.data;
      default:
        break;
    }
  }

  //CICLO DE VIDA
  useEffect(() => {
    let interval = setInterval(() => {
      let tk = obtenerStateStorage("token");
      let id = decodificar(tk);
      socket.emit('verifica-token', { "id": id?.usuario?.idUsuario, "token": tk,"idEntidad":auth?.configuraciones?.idEntidad });
    }, 900000);//1000  900000
    return () => clearInterval(interval);
  });


  return (
    <div className={classes.root}>
      <HashRouter>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift, classes.transparencia)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Panel de control
          </Typography>
            <SimpleMenu name={"Perfil"} icon={<PersonIcon />} user={auth.name}
              opciones={[
                { path: "/configuracion", layout: "/dashboard", name: "Configuración", type: "router", icon: <SettingsIcon /> },
                { name: "Cerrar sesión", evt: () => handleOnClickLogout(), type: "event", icon: <MeetingRoomIcon /> }
              ]}
            />
          </Toolbar>
        </AppBar>

        <Hidden xsDown> {/* Disponible para pc */}
          <Drawer
            variant="permanent"
            className={classes.sombrasMenu}
            classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <ListItems open={open} except={["Configuración"]} />
          </Drawer>
        </Hidden>


        <Hidden smUp> {/* Disponible para mobile */}
          <Drawer
            variant="temporary"
            classes={{
              paper: classes.drawerPaperMobile,
            }}
            open={open}
            onClose={handleDrawerOpen}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <ListItems open={open} except={["Configuración"]} />
          </Drawer>
        </Hidden>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Suspense fallback={<SimpleCircularProgress />}>
              {auth.tipoUsuario !== "CLIENTE" ? <Box pb={1}><SimpleBreadCrumb /></Box> : ""}
              <Switch>
                {functionGetRoutes(functionTipo(auth.tipoUsuario))}
                <Redirect from="*" to="/dashboard/index" />
              </Switch>
              <Box pt={4}>
                <Copyright name="WhiteCat" link="https://react-material-kit.devias.io/app/reports/dashboard" />
              </Box>
            </Suspense>
          </Container>
        </main>

      </HashRouter>
    </div>
  );
}
