import React from 'react';
//***CORE***
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
//***HELERS***
import { notification } from "../Helpers/validate";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog fullScreen open={props.datos.abrir} onClose={() => props.evtClose()} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => props.evtClose()} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="body1" className={classes.title}>
              {props.personalizar.titulo}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem>
            {notification(props.faltanCampos || "")}
          </ListItem>
          <DialogContent
            id="large-modal-slide-description-1">
            <props.componente data={props.data} />
          </DialogContent>
        </List>
      </Dialog>
    </div>
  );
}
