import React, { lazy } from "react";
//***ICONS***
import { DashboardIcon, DeveloperModeIcon, CardMembershipIcon, AccountBoxIcon, PhoneIphoneIcon, AccountTreeIcon, ListIcon } from './Utils/Constant/icons';
//***COMPONENTES***
const Metricas = lazy(() => import("./Dashboard/Metricas"));
const Configuracion = lazy(() => import("./Views/Configuracion"));
const CustomerView = lazy(() => import("./Views/DashboardCliente"));
const PagosClientes = lazy(()=> import("./Views/PagosCliente"));
const ControlSuscripciones = lazy(()=> import("./Views/ControlSuscripciones"));
const ControlUsuarios = lazy(()=> import("./Views/ControlUsuarios"));
const ControlAplicaciones = lazy(()=> import("./Views/Aplicaciones"));
const ControlVersiones = lazy(()=>import("./Views/Versiones"));
const Changelog = lazy(()=>import("./Views/Changelog"));

export let dashRoutesDueño={
  data:
  [
    {
    path: "/index",
    name: "Inicio",
    component: Metricas,
    layout: "/dashboard",
    icon:<DashboardIcon/>,
    iconColor:"cloudBlueColor",
    beforeLine: true, 
    afterLine: false,
    subheader:"Administración",
  },
  {
    name: "Desarrollos",
    icon: <DeveloperModeIcon/>,
    iconColor:"purpleColor",
    layout: "",
    beforeLine: false, 
    afterLine: false,
    collapse: true,
    children: [
      {
        path: "/aplicaciones",
        name: "Aplicaciones",
        icon:<PhoneIphoneIcon/>,
        iconColor:"purpleColor",
        component: ControlAplicaciones,
        layout: "/dashboard",
        beforeLine: false, 
        afterLine: false,
      },
      {
        path: "/versiones",
        name: "Versiones",
        icon:<AccountTreeIcon/>,
        iconColor:"purpleColor",
        component: ControlVersiones,
        layout: "/dashboard",
        beforeLine: false, 
        afterLine: false,
      }
    ]
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: ControlUsuarios,
    layout: "/dashboard",
    icon:<AccountBoxIcon/>,
    iconColor:"yellowColor",
    beforeLine: false, 
    afterLine: false,
  },
  {
    path: "/suscripciones",
    name: "Suscripciones",
    component: ControlSuscripciones,
    layout: "/dashboard",
    icon:<CardMembershipIcon/>,
    iconColor:"redColor",
    beforeLine: false, 
    afterLine: false
  },
  {
    path: "/changelog",
    name: "Changelog",
    component: Changelog,
    layout: "/dashboard",
    icon:<ListIcon/>,
    iconColor:"lightBlueColor",
    beforeLine: true, 
    afterLine: false,
    subheader:"Material app",
  },
  {
    path: "/configuracion",
    name: "Configuración",
    component: Configuracion,
    layout: "/dashboard",
    icon:<ListIcon/>,
    beforeLine: false, 
    afterLine: false,
  },
  
]
};

//ADMNISTRACCIÓN
export let dashRoutesAdmin = {
  data:
  [
    {
    path: "/index",
    name: "Inicio",
    component: Metricas,
    layout: "/dashboard",
    icon:<DashboardIcon/>,
    iconColor:"cloudBlueColor",
    beforeLine: true, 
    afterLine: false,
    subheader:"Administración",
  },
  {
    name: "Desarrollos",
    icon: <DeveloperModeIcon/>,
    iconColor:"purpleColor",
    layout: "",
    beforeLine: false, 
    afterLine: false,
    collapse: true,
    children: [
      {
        path: "/aplicaciones",
        name: "Aplicaciones",
        icon:<PhoneIphoneIcon/>,
        iconColor:"purpleColor",
        component: ControlAplicaciones,
        layout: "/dashboard",
        beforeLine: false, 
        afterLine: false,
      },
      {
        path: "/versiones",
        name: "Versiones",
        icon:<AccountTreeIcon/>,
        iconColor:"purpleColor",
        component: ControlVersiones,
        layout: "/dashboard",
        beforeLine: false, 
        afterLine: false,
      }
    ]
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: ControlUsuarios,
    layout: "/dashboard",
    icon:<AccountBoxIcon/>,
    iconColor:"yellowColor",
    beforeLine: false, 
    afterLine: false,
  },
  {
    path: "/suscripciones",
    name: "Suscripciones",
    component: ControlSuscripciones,
    layout: "/dashboard",
    icon:<CardMembershipIcon/>,
    iconColor:"redColor",
    beforeLine: false, 
    afterLine: false
  },
  {
    path: "/changelog",
    name: "Changelog",
    component: Changelog,
    layout: "/dashboard",
    icon:<ListIcon/>,
    iconColor:"lightBlueColor",
    beforeLine: true, 
    afterLine: false,
    subheader:"Material app",
  },
  {
    path: "/configuracion",
    name: "Configuración",
    component: Configuracion,
    layout: "/dashboard",
    icon:<ListIcon/>,
    beforeLine: false, 
    afterLine: false,
  },
  
]
};

//TECNICO
export let dashRoutesTecnico = {
  data:
  [{
    path: "/index",
    name: "Inicio",
    component: Metricas,
    layout: "/dashboard",
    icon:<DashboardIcon/>,
    iconColor:"cloudBlueColor",
    beforeLine: true, 
    afterLine: false,
    subheader:"Administración",
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: ControlUsuarios,
    iconColor:"yellowColor",
    layout: "/dashboard",
    icon:<AccountBoxIcon/>,
    beforeLine: false, 
    afterLine: false,
  },
  {
    path: "/suscripciones",
    name: "Suscripciones",
    iconColor:"redColor",
    component: ControlSuscripciones,
    layout: "/dashboard",
    icon:<CardMembershipIcon/>,
    beforeLine: false, 
    afterLine: false
  },
  {
    path: "/changelog",
    name: "Changelog",
    component: Changelog,
    layout: "/dashboard",
    icon:<ListIcon/>,
    iconColor:"lightBlueColor",
    beforeLine: false, 
    afterLine: false,
    subheader:"Material app",
  },
  {
    path: "/configuracion",
    name: "Configuración",
    component: Configuracion,
    layout: "/dashboard",
    icon:<ListIcon/>,
    beforeLine: false, 
    afterLine: false,
  },
]
};

//CLIENTE
export let dashRoutesCliente = {
  data:
  [{
    path: "/index",
    name: "Inicio",
    component: CustomerView,
    layout: "/dashboard",
    icon:<DashboardIcon/>,
    iconColor:"cloudBlueColor",
    beforeLine: true, 
    afterLine: false,
    subheader:"Administración",
  },
  {
    path: "/suscripciones",
    name: "Mis suscripciones",
    component: PagosClientes,
    layout: "/dashboard",
    icon:<CardMembershipIcon/>,
    iconColor:"redColor",
    beforeLine: false, 
    afterLine: false
  },
  {
    path: "/configuracion",
    name: "Configuración",
    component: Configuracion,
    layout: "/dashboard",
    icon:<ListIcon/>,
    beforeLine: false, 
    afterLine: false,
  },
]
};
