import React from 'react';
//***DEPENDENCIAS***
import PropTypes from 'prop-types';
//***CORE***
import { makeStyles, MuiAlert, useTheme } from '../Constant/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function CustomizedSnackbars(props) {
    //DECLARACION
    const { titulo, contenido, tipo } = props;
    const classes = useStyles();
    let theme = useTheme();


    const typeSnackbars = (types, titulo, contenido) => {
        let type = null;
        switch (types) {
            case "correcto":
                type = "success";
                break
            case "informacion":
                type = "info"
                break
            case "advertencia":
                type = "warning"
                break;
            case "error":
                type = "error"
                break;
            default:
                break;
        }

        if (type != null) {
            return (
                <Alert style={{ backgroundColor: theme.palette.primary.main }} severity={type}>
                    <span style={{ fontWeight: "bold" }}>{titulo}</span> {" "}{contenido}
                </Alert>
            )
        }
    }

    return (
        <div className={classes.root} style={{marginTop:"10px"}}>
            {typeSnackbars(tipo, titulo, contenido)}
        </div>
    );
}


CustomizedSnackbars.propTypes = {
    titulo: PropTypes.string.isRequired,
    contenido: PropTypes.string.isRequired,
    tipo: PropTypes.string.isRequired,
};

export default CustomizedSnackbars;