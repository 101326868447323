import { useState } from "react";
import { ftFechaRangoValidaHoy, ftFechaValida } from "../../Utils/Helpers/validarFecha";

export const useForm = (initialState = {}, _options) => {

    //DECLARACIONES
    const [values, setValues] = useState(initialState);

    //EVENTOS

    const handleInputChange = (e) => {

        let { name, value } = targetValue(e).input;
        typeOfValidation(name, value);
    }

    const reset = () => {
        setValues(initialState);
    }

    const targetValue = (e) => {
        let name = null, value = null;
        let { name: nameTemp1, value: valueTemp1 } = e.currentTarget;
        let { name: nameTemp2, value: valueTemp2 } = e.target;
        if (nameTemp1 !== undefined && nameTemp1 !== null) {
            name = nameTemp1;
        } else {
            name = nameTemp2;
        }

        if (valueTemp1 !== undefined && valueTemp1 !== null) {
            value = valueTemp1;
        } else {
            value = valueTemp2;
        }

        return {
            input: { name, value }
        }
    }

    const typeOfValidation = (name, value) => {
        let type;
        for (const prop in values.input[name].options) {
            if (values.input[name].options[prop] === true) {
                type = prop;
            }
        }
        switch (type) {
            case 'rgx':
                verifyRgx(name, value)
                break;
            case 'file':

                break;
            case 'img':

                break;
            case 'dateLow':
                verifyDateLow(name,value)
                break;
                
            default:
        }
    }

    const verifyRgx = (name, value) => {
        let rgxTemp;
        if (values.input[name].rgx !== '' && values.input[name].rgx !== null) {
            rgxTemp = new RegExp(values.input[name].rgx);
            if (rgxTemp.test(value)) {
                setValues(values=>({
                    ...values,
                    input: {
                        ...values.input,
                        [name]: {
                            ...values.input[name],
                            valor: value,
                            error: false
                        }
                    }
                }));
            } else {
                  setValues(values=>({
                    ...values,
                    input: {
                        ...values.input,
                        [name]: {
                            ...values.input[name],
                            valor: value,
                            error: true
                        }
                    }
                }));
             }

        } else {
            if (value === null || value.length === 0) {
                setValues(values=>({
                    ...values,
                    input: {
                        ...values.input,
                        [name]: {
                            ...values.input[name],
                            valor: value,
                            error: true
                        }
                    }
                }));
            } else {
                setValues(values=>({
                    ...values,
                    input: {
                        ...values.input,
                        [name]: {
                            ...values.input[name],
                            valor: value,
                            error: false
                        }
                    }
                }));
            }
        }
    }

    const verifyDateLow = (name, value) => {
        if (value !== null && value.length !== 0 && value !== "" && ftFechaRangoValidaHoy(value) === true && ftFechaValida(value) === false) {
                setValues(values=>({
                    ...values,
                    input: {
                        ...values.input,
                        [name]: {
                            ...values.input[name],
                            valor: value,
                            error: false
                        }
                    }
                }));
            } else {
                setValues(values=>({
                    ...values,
                    input: {
                        ...values.input,
                        [name]: {
                            ...values.input[name],
                            valor: value,
                            error: true
                        }
                    }
                }));
            }
    }

    return [values,handleInputChange,reset];

}
