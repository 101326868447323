export const obtenerStateStorage = tipo => {
    try {
      let userStorage = localStorage.getItem(tipo);
      if (userStorage === null) {
        return undefined;
      } else {
        return JSON.parse(userStorage);
      }
    } catch (error) {
      return undefined;
    }
  };
  
  export const guardarStateStorage = (state, tipo) => {
    try {
      const userStorage = JSON.stringify(state);
      localStorage.setItem(tipo, userStorage);
    } catch (e) {
  
    }
  
  };
  